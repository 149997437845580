import axios from 'axios';
import React, { useRef } from 'react'
import PrivateData from './PrivateData';
import Connect from '../common/connect';
import img_wordmark from '../etc/img/WinitechCloud_3.png'
const EnumsRequestType={
    'TYPE1':'클라우드 전환',
    'TYPE2':'클라우드 운영',
    'TYPE3':'클라우드 보안',
    'TYPE4':'기타 문의사항'

}
function RequestForm(props) {
    // const dispatch = useDispatch();
    const dialogRef = useRef(null);
    const [writeData, setWriteData] = React.useState({
        id:'',
        requestType:'',
        title:'',
        content:'',
        // files:'',
        name:'',
        email:'',
        phone:''
    })
    const [ piChk , setPiChk ] = React.useState(false);
    const [files,setFiles] = React.useState([]);
    const openDialog = () => {
        if (dialogRef.current) {
          dialogRef.current.showModal();
        }
      };
    
    const closeDialog = () => {
    if (dialogRef.current) {
        dialogRef.current.close();
    }
    };
    const chk_accept = ()=>{
        // setWriteData({
        //     ...writeData,
        //     chk :true
        // }) 
        setPiChk(true)
        closeDialog();
    }
    const onChangeData = (e)=>{
        if([e.target.name]=='files'){
            let list = [];
            let i = 0 ;
            for(i = 0 ; i< e.target.files.length;i++){
                list.push(e.target.files[i])
            }
            setFiles(list)
            // setWriteData({
            //     ...writeData,
            //     [e.target.name] : `선택된 파일 ${i+1}개`
            // })
        }
        else{
            setWriteData({
                ...writeData,
                [e.target.name] : e.target.value
            })   
        }
        
        
    }
    const changePrivate = (e)=>{
        setPiChk(e.target.checked);
        // setWriteData({
        //     ...writeData,
        //     chk : 
        // }) 
    }
    const reset = ()=>{
        setWriteData({
            id:'',
            requestType:'TYPE1',
            title:'',
            content:'',
            // files:'',
            name:'',
            email:'',
            phone:''
        })
        document.getElementById('form_files').value=''
        setPiChk(false)
        setFiles([])
    }
    const validateEmail=(email) =>{
        // 이메일 주소를 검증하기 위한 정규식
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }
    const validateNumber=(phone) =>{
        // 전화번호를 검증하기 위한 정규식
    // 국가 코드를 포함한 경우: +국가코드-전화번호
    // 국가 코드를 포함하지 않은 경우: 전화번호
        const pattern = /^\+?(\d{1,3})?[-.\s]?\(?\d{3}\)?[-.\s]?\d{3,4}[-.\s]?\d{4}$/;
        return pattern.test(phone);
    }
    const errorCheck = ()=> {
        let rtnChk = true;

        if( writeData.requestType == ''){
            alert('문의 유형을 선택해주세요');
             return false;
        }
        if( writeData.title == ''){
            alert('제목을 작성해주세요');
            return  false;
        }
        if( writeData.content == ''){
            alert('문의 내용을 작성해주세요');
            return  false;
        }
        if( writeData.name == ''){
            alert('작성자 이름을 작성해주세요');
            return  false;
        }
        if( writeData.email =='' &&  writeData.phone ==''){
            alert('연락받을 메일이나 전화번호를 작성해주세요.');
            return  false;
        }
        if( writeData.email !==''){
            const chk = validateEmail(writeData.email)
            if(!chk){
                alert('메일주소 형식을 확인해주세요')
                return  false;
            }
        }
        if( writeData.phone !==''){
            const chk = validateNumber(writeData.phone)
            if(!chk){
                alert('전화번호 형식을 확인해주세요')
                return  false;
            }
        }
        if( piChk ==false){
            alert('개인정보 수집·이용에 대하여 동의해주세요.')
            return  false;
        }
        return true;
    }
    const save = async()=>{
        let chk = errorCheck(); //에러체크
        if(!chk) return; 
        //라벨로바꾸기
        // writeData.requestType = EnumsRequestType[writeData.requestType]
        let fileList = files;
        const formdata = new FormData();
        formdata.append('data', JSON.stringify(writeData)); //첫번째 파라메타는 데이터를 넣어서 전송
        
        //두번째 파라메터 부터는 파일업로드
        for(let i = 0 ; i< files.length;i++){
            formdata.append(`file${i}`,files[i])
        }

        // const response = await axios.post('http://43.203.168.159:8092/api/v1/crm/crm-request', formdata, {
    // const response = await axios.post('http://crm.cloud-winitech.com:8092/api/v1/crm/crm-request', formdata, {
        const connect = new Connect()
        const response = await connect.axios.post('/api/v1/crm/crm-request', formdata, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
        })
        if(response.data.data==='OK'){
            reset()
            alert("문의내용이 저장되었습니다.");
        }else{
            alert("문의내용 저장에 실패하였습니다. 잠시후 다시 시도해주세요.");
            return
        }
    }

    return (
        <div className='pt-10 text-left'>
            <section className='text-center text-blue-900 font-bold pb-4 text-lg flex justify-center'>
            <img src={img_wordmark} style={{width:'13rem',height:'1.7rem',marginRight:5}}/><p className="header"> 1:1 문의하기</p>
            </section>
            <div >
                <form className="max-w-md mx-auto">
                    <label htmlFor="countries" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><span className="text-red-500">&nbsp;*&nbsp;</span>문의 유형</label>
                    <select required  id="countries" className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" selected= {writeData.requestType} name ='requestType' onChange={onChangeData}>
                        <option value = '' selected className={'text-gray-200'}>문의 유형을 선택하세요</option>
                        {
                            Object.keys(EnumsRequestType).map((item)=>{

                                return(<option value={item}>{EnumsRequestType[item]}</option>)
                            })
                        }
                    </select>
                </form>
            </div>
            <div>
                <form className="max-w-md mx-auto pt-2">
                    <label htmlFor="form_title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><span className="text-red-500">&nbsp;*&nbsp;</span>제목</label>
                    <input id="form_title" required className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value= {writeData.title}  name ='title' onChange={onChangeData}/>
                </form>
            </div>
            <div>
                <form className="max-w-md mx-auto pt-2">
                    <label htmlFor="form_content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><span className="text-red-500">&nbsp;*&nbsp;</span>문의 내용</label>
                    <textarea id="form_content" rows="7" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your thoughts here..." value= {writeData.content} name ='content' onChange={onChangeData}></textarea>
                </form>
            </div>
            <div>
                <form className="max-w-md mx-auto pt-2">
                    <label htmlFor="form_files" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">파일첨부</label>
                    <input id="form_files" type="file" multiple className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" value= {writeData.files} name ='files'  onChange={onChangeData}/>
                </form>
            </div>
            <div>
                <form className="max-w-md mx-auto pt-2">
                    <label htmlFor="form_email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><span className="text-red-500">&nbsp;*&nbsp;</span>이름</label>
                    <input id="form_email" className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"value= {writeData.name} name ='name' onChange={onChangeData}/>
                </form>
            </div>
            <div>
                <form className="max-w-md mx-auto pt-2">
                    <label htmlFor="form_email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><span className="text-blue-500">&nbsp;*&nbsp;</span>이메일</label>
                    <input id="form_email" className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value= {writeData.email} name ='email' onChange={onChangeData} placeholder = "예) winitech01@winitech.com"/>
                </form>
            </div>
            <div>
                <form className="max-w-md mx-auto pt-2">
                    <label htmlFor="form_hp" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><span className="text-blue-500">&nbsp;*&nbsp;</span>휴대폰 번호</label>
                    <input id="form_hp" className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value= {writeData.phone} name ='phone' onChange={onChangeData} />
                </form>
            </div>
            <div>
                <form className="max-w-md mx-auto pt-2 border-t-gray-200 border-t mt-2 border-b-gray-200 border-b pb-2">
                    <label htmlFor="form_hp" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">개인정보 수집 및 이용에 대한 안내 </label>
                    <div className={'flex flex-row justify-between'}>
                        <div className ={"rounded-md border border-blue-300 border-1 pl-2 pr-2 pb-0 text-center"} onClick={openDialog}><label htmlFor="form_hp" className="block text-sm font-medium text-gray-900 dark:text-white">내용 보기</label></div>
                        <div className={`flex flex-row `}>
                            <input id="checked-pinfo" type="checkbox" value=""style={{marginTop:'2px'}} className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" checked={piChk} onClick={changePrivate}/>
                            <label htmlFor="checked-pinfo" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white ml-1">개인정보 수집 및 이용에 동의합니다<p className='text-red-500 float-right ml-1'>(필수)</p> </label>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <form className="max-w-md mx-auto pt-2">
                    <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={save}>문의하기</button>
                </form>            
            </div>

            <dialog ref={dialogRef}>
                {/* <h1>Dialog </h1> */}
                {/* {getContent()} */}
                {/* <div style={{width:30,height:53}}> */}
                    <PrivateData close = {closeDialog} accept = {chk_accept}/>
                {/* </div> */}
                {/* <button onClick={closeDialog}>Close</button> */}
            </dialog>
            
        </div>
    )
}

export default RequestForm