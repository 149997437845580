import React from 'react'
// import logo from '../etc/img/ico_updownlogo.png'
import logo from '../etc/img/WinitechCloud_1@2x.png'
import RequestForm from './RequestForm'
import Organization from './Organization';
import img_wordmark from '../etc/img/img_wordmark.png'
// import img_wordmark from '../etc/img/WinitechCloud_3.png'

import Faq from './Faq';
function Main(props) {
    const [active, setActive] = React.useState('request');
    const eventActiveMenu = (e)=>{
        // console.log(e)      
        setActive(e);  
    }
    return (
        <main className={'flex flex-col justify-between'} style={{margin:0,padding:0,minHeight:'100vh'}}>
        {/* // <main className={'h-screen '} style={{margin:0,padding:0}}> */}
            {/* header */}
            {/* <section>
                <div className="bg-slate-50 h-12 sticky divide-y divide-blue-200"  >
                   <ul>
                    <li className ="float-left "><button className={"border-b-fuchsia-700 h-max min-w-80 text-center"}>title</button></li>
                    <li className ="float-left"><button className={'min-w-50'}>FAQ</button></li>
                    <li className ="float-left"><button className={'min-w-50'}>1:1 문의하기</button></li>
                    <li className ="float-left"><button className={'min-w-50'}>조직체계</button></li>
                   </ul>
                </div>
            </section> */}
            <nav className="bg-gray-100 border-gray-200 dark:bg-gray-900 m-0 p-0">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 border-gray-300 border-b">
                    <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={logo} className="h-9" alt="Logo" />
                        <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white text-blue-900"> | 클라우드 고객의견 수렴채널</span>
                    </a>
                    {/* <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                        </svg>md:
                    </button> */}
                    <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul className="bg-gray-100 font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-gray-100 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li onClick={()=> eventActiveMenu('faq')}>
                                <a href="#" className={`block py-2 px-3 ${active=='faq'?'text-blue-700': 'text-gray-900'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`} aria-current="page">FAQ</a>
                            </li>
                            <li onClick={()=> eventActiveMenu('request')}>
                                <a href="#" className={`block py-2 px-3 ${active=='request'?'text-blue-700': 'text-gray-900'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>1:1 문의하기</a>
                            </li>
                            <li onClick={()=> eventActiveMenu('groupmap')}>
                                <a href="#" className={`block py-2 px-3 ${active=='groupmap'?'text-blue-700': 'text-gray-900'} rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}>조직구성</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <section className = {'contents max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 mb-auto' }>
                <div  style={{minHeight:'67.5vh'}}>
                {   active=='request'? <RequestForm/>:null }
                {   active=='groupmap'? <Organization/>:null }
                {   active=='faq'? <Faq/>:null }
                </div>
            </section>
            <footer class="bg-white shadow dark:bg-gray-900">
                <div class="w-full max-w-screen-xl mx-auto md:py-8">
                    {/* <div class="sm:flex sm:items-center sm:justify-between"> */}
                    <div class="text-left">
                        <a href="https://www.winitech.com/" target="_blank">
                            <img src={img_wordmark} class="h-8 mb-2" alt=" Logo" />
                        </a>    
                        <div>
                            <span class="text-xs text-gray-500 font-semibold whitespace-nowrap dark:text-white">클라우드 고객지원 채널 </span>
                        </div>
                        <div>    
                            <span class="text-xs text-gray-500  whitespace-nowrap dark:text-white">전화: 053-659-1830 E-mail: winitech.tech@gmail.com</span>
                        </div>
                        <div>
                            <span class="text-xs text-gray-500 whitespace-nowrap  dark:text-white">대구광역시 달서구 송현로 205, 2층(본동, 대구공업대학교 복지관)</span>
                        </div>
                    </div>
                    <hr class="my-4 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-4" />
                    <span class="block text-xs text-gray-400 sm:text-left dark:text-gray-400">COPYRIGHT <a href="https://www.winitech.com/" class="hover:underline">(C)Winitech company</a>. All Rights Reserved.</span>
                </div>
            </footer>
        </main>
    )
}

export default Main