import axios from "axios";
const axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      //"Access-Control-Allow-Origin": "*",
    },
};
// axios.defaults.baseURL = process.env.REACT_APP_CRM_API_UR;
// console.log(axios.defaults.baseURL)
class Connect {
    constructor() {
        // axios.defaults.headers.common['Authorization']='Bearer '+ localStorage.getItem('jwtsessiontoken'); // 세션 정보 넘기기 
        this.axios = axios;
        this.axios.defaults.baseURL = process.env.REACT_APP_CRM_API_URL;
    }
}

export default Connect;