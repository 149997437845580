import React from 'react'
import img_wordmark from '../etc/img/WinitechCloud_3.png'

export default function Organization(props) {
    return (
        <div id='organization'>
            <section className='text-center text-blue-900 font-bold pt-10 pb-4 text-lg flex justify-center'>
                <img src={img_wordmark} style={{width:'13rem',height:'1.7rem',marginRight:5}}/><p className=""> 네이티브 혁신센터 조직 구성도</p>
            </section>
            <section className="treechart">
                <div className="box lv1"><h2>대표이사</h2></div>
                <div className="box lv2"><h3>부사장</h3></div>
                <ul className="lv3">
                    <li>
                        <div className="box">기술총괄실</div>
                        <ul className="lv4">
                            <li>
                                <div className="box " style={{background:'#2d74d6'}}>클라우드 네이티브 혁신센터</div>
                                <ul className="lvLast">
                                    <li>
                                        <dl>
                                            <dt>클라우드네이티브 전환팀</dt>
                                            <dd>고객 MSA도입/설계 지원</dd>
                                            <dd>이벤트스토밍 퍼실리테이팅</dd>
                                            <dd>클라우드 인프라 기획</dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <dl>
                                            <dt>클라우드 네이티브 운영팀</dt>
                                            <dd>Devops 파이프라인 관리</dd>
                                            <dd>서비스 모니터링 및 장애대응</dd>
                                            <dd>SRE(Site Reliability Engineering)</dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <dl>
                                            <dt>클라우드 보안팀</dt>
                                            <dd>침해사고 대응</dd>
                                            <dd>고객사 개인정보 관리</dd>
                                        </dl>
                                    </li>
                                    <li>
                                        <dl>
                                            <dt>클라우드 고객지원 채널</dt>
                                            <dd>클라우드 전환 고객 상담</dd>
                                            <dd>서비스 의견 수렴 및 고객 응대</dd>
                                            <dd>기술지원 접수</dd>
                                        </dl>
                                    </li>
                                </ul>
                            </li>
                            <li><div className="box" style={{background:'white', color:'#6a6a6a',borderWidth :2,  borderColor:'#627692',borderStyle:'solid'}}>AI</div></li>
                        </ul>
                    </li>
                    <li><div className="box" style={{background:'white', color:'#6a6a6a',borderWidth :2,  borderColor:'#627692',borderStyle:'solid'}}>품질보증팀</div></li>
                </ul>

            </section>
        </div>
        
    )
}
