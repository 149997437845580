import React from 'react'

export default function PrivateData(props) {
    
    return (
        <div id="default-modal" tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full  ">
            <div className="relative w-full max-w-2xl max-h-full  ">
                <div className="relative bg-white shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            개인정보 수집 · 이용 동의
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal" onClick={props.close}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            위니텍은 클라우드 고객 수렴 채널 서비스 고객 상담 및 고지사항 전달 등을 위해 아래와 같이 개인정보를 수집 · 이용합니다.
                        </p>
                        <table className="w-full table-auto border-collapse border border-slate-100 p-2  text-gray-700">
                            <thead className={'bg-gray-100 '}>
                                <tr>
                                    <th className={"w-1/5 pl-2"}>
                                        수집목적
                                    </th>
                                    <th className={"w-2/5"}>
                                        수집항목
                                    </th>
                                    <th>
                                        수집근거
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td className={'pl-2'}>고객상담</td>
                                    <td>이메일, 전화번호</td>
                                    <td><a href="https://www.law.go.kr/LSW/lsInfoP.do?lsId=011357&ancYnChk=0#0000" target="_blank">개인정보보호법 제15조 제1항</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            귀하는 위니텍 클라우드 고객 수렴채널 서비스 이용에 필요한 최소한의 개인정보 수집 이용에 동의하지 않을 수 있으나, 동의를 거부할 경우 이용이 불가합니다.
                        </p>
                    </div>
                    <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 ">
                        <button data-modal-hide="default-modal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={props.accept}>동의</button>
                        <button data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" onClick={props.close}>닫기</button>
                    </div>
                </div>
            </div>
            
        </div>
    )
}